"use strict";
import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
const QUERY_STALE_TIME_MS = 6e4;
const QUERY_CACHE_TIME_MS = 3e5;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: QUERY_STALE_TIME_MS,
      gcTime: QUERY_CACHE_TIME_MS
    }
  }
});
export const QueryClientWrapper = ({ children }) => React.createElement(QueryClientProvider, { client: queryClient }, children);
