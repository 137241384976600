"use strict";
import { BaseHttpRequest } from "./BaseHttpRequest";
import { request as __request } from "./request";
export class AxiosHttpRequest extends BaseHttpRequest {
  constructor(config) {
    super(config);
  }
  request(options) {
    return __request(this.config, options);
  }
}
